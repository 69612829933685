<template>
  <div class="page-main">
    <!-- 表格操作条 -->
    <div class="m-b-10">
      <el-button type="success" size="mini" @click="itemAdd">新增单位</el-button>
    </div>

    <!-- 表格 -->
    <vxe-grid ref="refTable" v-bind="tableOptions">
      <template #default_operate="{ row }">
        <vxe-button icon="el-icon-edit-outline" title="编辑" circle @click="itemEdit(row)" />
        <vxe-button icon="el-icon-delete" title="删除" circle @click="itemDelete(row)" />
      </template>
      <template #pager>
        <div></div>
        <vxe-pager v-bind="tableOptions.pagerConfig" @page-change="pageChange" />
      </template>
    </vxe-grid>

    <!-- 增改单位弹窗 -->
    <PopupUnit
      v-if="isShowPopupUnit"
      :show.sync="isShowPopupUnit"
      :data.sync="itemObj"
      @success="getListData"
    />
  </div>
</template>

<script>
import PopupUnit from './popup-unit'
export default {
  components: {
    PopupUnit
  },
  data() {
    return {
      tableOptions: {
        size: 'mini',
        border: true,
        'max-height': this.$util.getViewHeight() - 150,
        pagerConfig: {
          ...this.$constant.page
        },
        columns: [
          { field: 'id', width: 100, title: '编号' },
          { field: 'name', title: '单位' },
          { field: 'shortcut_key', title: '快捷编码' },
          { title: '操作', width: 160, slots: { default: 'default_operate' } }
        ],
        data: []
      },
      isShowPopupUnit: false,
      itemDefault: {
        id: '',
        name: '',
        shortcut_key: ''
      },
      itemObj: {}
    }
  },
  mounted() {
    this.initItemObj()
    this.getListData()
  },
  methods: {
    // 初始化itemObj
    initItemObj() {
      this.itemObj = this.$util.deepCopy(this.itemDefault)
    },
    // 获取列表数据
    getListData() {
      this.$api({
        method: 'post',
        url: '/admin/unitSetting',
        params: {
          currentPage: this.tableOptions.pagerConfig.currentPage,
          pageSize: this.tableOptions.pagerConfig.pageSize
        }
      }).then((res) => {
        if (res.status === 200) {
          this.tableOptions.data = res.data.page.list
          this.tableOptions.pagerConfig.pageSize = res.data.page.pageSize
          this.tableOptions.pagerConfig.total = res.data.page.totalRow
          this.tableOptions.pagerConfig.currentPage = res.data.page.pageNumber
        }
      })
    },
    // 分页切换
    pageChange({ currentPage, pageSize }) {
      this.tableOptions.pagerConfig.currentPage = currentPage
      this.tableOptions.pagerConfig.pageSize = pageSize
      this.getListData()
    },
    // 项-新增
    itemAdd() {
      this.isShowPopupUnit = true
      this.initItemObj()
    },
    // 项-编辑
    itemEdit(row) {
      this.isShowPopupUnit = true
      this.itemObj = this.$util.deepCopy(row)
    },
    // 项-删除
    itemDelete(row) {
      this.$XModal.confirm('您确定要删除该数据?').then((type) => {
        if (type === 'confirm') {
          // 传送删除动作
          this.$api({
            method: 'post',
            url: '/admin/unitSetting/deleteVue',
            params: {
              id: row.id
            }
          }).then((res) => {
            if (res.data.state === 'ok') {
              this.$message.success('删除成功')
              this.getListData()
            } else {
              this.$message.error(res.data.msg)
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.page-main {
}
</style>
